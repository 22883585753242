export default {
  achievement: 'fa fa-trophy',
  add: 'fa fa-plus',
  addUser: 'fa fa-user-plus',
  applied: 'fa fa-circle-check',
  archive: 'fa fa-box-archive',
  assessments: 'fa fa-square-list',
  assessmentControls: 'fa fa-intersection',
  attach: 'fa fa-paperclip-vertical',
  automations: 'fa fa-robot',
  check: 'fa fa-check',
  clone: 'fa fa-clone',
  close: 'fa fa-times',
  columns: 'fa fa-table-columns',
  comment: 'fa fa-comments',
  complete: 'fa fa-circle-check',
  complianceCalendar: 'fa fa-calendar-days',
  controls: 'fal fa-solar-system',
  copy: 'fa fa-copy',
  criticality: 'fa fa-circle-exclamation',
  danger: 'fa fa-triangle-exclamation',
  date: 'fa fa-calendar-days',
  delete: 'fa fa-trash-can',
  download: 'fa fa-download',
  upCaret: 'fa fa-caret-up',
  downCaret: 'fa fa-caret-down',
  rightCaret: 'fa fa-caret-right',
  leftCaret: 'fa fa-caret-left',
  edit: 'fa fa-pen-to-square',
  execute: 'fa fa-function',
  export: 'fa fa-file-export',
  file: 'fa fa-file-lines',
  filter: 'fa fa-filter',
  folder: 'fa fa-folder',
  goTo: 'fa fa-arrow-right-to-bracket',
  hide: 'fa fa-eye-slash',
  home: 'fa fa-house',
  horizontalCollapse: 'fa fa-chevron-left',
  horizontalExpand: 'fa fa-chevron-right',
  verticalUp: 'fa fa-chevron-up',
  verticalDown: 'fa fa-chevron-down',
  integrations: 'fa fa-webhook',
  inventories: 'fa fa-briefcase',
  keys: 'fa fa-key',
  jira: 'fab fa-atlassian',
  link: 'fa fa-link',
  lock: 'fa fa-regular fa-lock',
  managementReview: 'fa fa-check-double',
  map: 'fa fa-map',
  merge: 'fa fa-merge',
  moreFunction: 'fa fa-regular fa-ellipsis',
  moreInfo: 'fa fa-circle-info',
  move: 'fa fa-arrow-down-arrow-up',
  notifications: 'fa fa-bell',
  orgSettings: 'fa fa-lock-keyhole',
  penetrationTesting: 'fa fa-user-secret',
  policies: 'fa fa-scroll',
  projectManagement: 'fa fa-clipboard-list',
  questionnaires: 'fa fa-poll-people',
  rearrange: 'fa fa-grip-dots-vertical',
  remove: 'fa fa-times',
  removeUsers: 'fa fa-user-times',
  reporting: 'fa fa-square-poll-vertical',
  restore: 'fa fa-trash-undo',
  reset: 'fa fa-arrows-rotate',
  riskRegisters: 'fa fa-hexagon-exclamation',
  save: 'fa fa-save',
  scope: 'fa fa-sitemap',
  scoring: 'fa fa-analytics',
  search: 'fa fa-magnifying-glass',
  selected: 'fa fa-square-check',
  selfAssessments: 'fa fa-table',
  send: 'fa fa-paper-plane',
  settings: 'fa fa-gear',
  show: 'fa fa-eye',
  sliders: 'fa fa-sliders-up',
  sort: 'fa fa-sort',
  sortDown: 'fa fa-sort-down',
  sortUp: 'fa fa-sort-up',
  splitScreen: 'fa fa-table-columns',
  subtasks: 'fa fa-list-tree',
  tags: 'fa fa-tags',
  tasks: 'fa fa-list-ul',
  unlink: 'fa fa-link-slash',
  unlock: 'fa fa-lock-open',
  unselected: 'fa fa-regular fa-square',
  uploadImport: 'fa fa-cloud-arrow-up',
  user: 'fa fa-user',
  users: 'fa fa-users',
  usersGear: 'fa fa-users-gear',
  userPlus: 'fa fa-user-plus',
  vendorManagement: 'fa fa-address-book',
  versionHistory: 'fa fa-clock-rotate-left',
  verticalCollapse: 'fal fa-solid fa-compress',
  verticalExpand: 'fal fa-solid fa-expand',
  vulnerabilitiesManagement: 'fa fa-bug',
  warning: 'fa fa-triangle-exclamation',
  zoomMinus: 'fa fa-search-minus',
  zoomPlus: 'fa fa-search-plus',
}
